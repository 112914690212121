
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// import colors
@import './assets/styles/colors';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$AngularAgencies-primary: mat.define-palette(mat.$indigo-palette);
$AngularAgencies-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$AngularAgencies-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$AngularAgencies-theme: mat.define-light-theme((
  color: (
    primary: $AngularAgencies-primary,
    accent: $AngularAgencies-accent,
    warn: $AngularAgencies-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($AngularAgencies-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

// import fonts
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Open+Sans:wght@400;600;700&display=swap');

html,
body {
  height: 100%;
}

body {
  font-family: 'Inter', sans-serif;
  font-size: .8rem;
  margin: 0;
}

// Katrhyn's custom styles
header, footer {
  width: 100%;
}

footer {
  border-top: 1px solid $border-secondary;
  padding: 1.125rem 0;
  text-align: center;
  height: auto;

  a {
    color: $text-tertiary;
    cursor: pointer;
    margin-bottom: 1.175rem;
    margin-right: 1.5rem;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      color: $FSX-primary;
      text-decoration: underline;
    }
  }
}

h3 {
  color: #242424;
  font-size: 1.0625rem;
  font-weight: 600;
  line-height: 1.375rem;
  margin-bottom: 1.125rem;
}

h4 {
  color: #000;
  font-size: .75rem;
  font-weight: 600;
  margin: 0;
}

a {
  color: $FSX-secondary;
  cursor: pointer;
  font-size: .75rem;
  font-weight: 500;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.mat-drawer-container {
  background-color: #fff !important;
}

label {
  color: $text-tertiary;
  font-size: .625rem;
  font-weight: 600;
  line-height: .875rem;
}

form {
  display: flex;
  flex-direction: column;
  width: 365px;

  select, input {
    border: solid 1px $border;
    box-sizing: border-box;
    height: 24px;
    margin-bottom: 1rem;
    padding: 0px 5px;

    &:disabled {
      background-color: $button-secondary-disabled;
    }
  }

  .button-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    button {
      min-width: 90px;
      padding: 0 .75rem;
    }
  }
}

// sticky header table styling
.sticky-table {
  margin-top: 1.375rem;

  thead th {
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 1;
  }
}

.form-error p {
  color: $FSX-warn;
  font-size: .625rem;
  font-weight: 500;
  margin: .3125rem 0 0 .3125rem;
}

button {
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: .75rem;
  font-weight: bold;
  height: 28px;
}

.error-message {
  color: $FSX-warn;
}

.mat-mdc-menu-content {
  padding: 1rem 0.5rem !important;
}

.mat-icon.icon-info {
  height: 12px !important;
  width: 12px !important;
}

.mat-icon.icon-sort {
  height: 12px !important;
}

.mat-icon.reply-icon {
  height: 16px !important;
  width: 16px !important;
}

// Mat menu

.mat-mdc-menu-panel {
  border-radius: 0;
}

.mat-mdc-menu-item {
  padding: 0 10px !important;

  &:focus {
    background-color: $FSX-primary !important;
    color: #fff !important;
  }
}

.mat-sidenav {
  border-right: none !important;
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px $scrollbar-track;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: $scrollbar-thumb;
  -webkit-box-shadow: inset 0 0 6px $scrollbar-track;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: $scrollbar-inactive;
}

/**
  Override pdf-viewer scrollbar width.
*/
.pdf-js-version-4-6 {
  ::-webkit-scrollbar {
    width: 5px;
  }
}

// Need to update card component with these styles in the future
.card-container {
  padding: 20px;

  .card-title {
    font-size: 1rem !important;
  }

  .card-hover-text {
    font-size: .75rem !important;
  }
}

.filing-cards {
  .card-container {
    padding: 0 !important;

    .card-title {
      font-size: .75rem !important;
    }
  }
}

.filing-menu,
.add-parties-menu {
  &.mat-mdc-menu-panel {
    margin-top: 7px;
  }

  .mat-mdc-menu-content {
    padding: 0 !important;
  }
}

.preview-docs-menu {
  &.mat-mdc-menu-panel {
    margin-top: -30px;
    margin-right: 10px;
  }

  .mat-mdc-menu-content {
    padding: 0 !important;
  }
}


fsx-modal {
  .actions {
    border-top: none;
  }
}


// Mat sidenav
mat-sidenav-container .mat-drawer-content {
  display: flex;
  flex-direction: column;
  //min-height: 100vh;

  .sidenav-content {
    flex: 1;
  }
}

// Mat date range picker
.date-picker {
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 2px;
  }
}

.mat-mdc-form-field-type-mat-date-range-input {
  height: 24px;
  width: 200px;

  .mat-mdc-form-field-focus-overlay {
    display: none;
  }

  .mat-mdc-text-field-wrapper {
    background-color: white !important;
    border: 1px solid $border;
    border-radius: 0;
    padding-left: .5rem;
  }

  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    min-height: unset;
    padding-bottom: 4px;
    padding-top: 5px;
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 50%;
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
    height: auto;
    padding: 0;
    width: 25px;

    svg {
      height: 18px;
    }
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-line-ripple::before {
    border-bottom: none;
  }

  .mdc-line-ripple {
    display: none;
  }
}

// Overriding select styles for filters
.filters {
  .select-container {
    margin-bottom: 0 !important;
  }
}

// Material checkbox
.mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
  border-color: $border !important;
}

.mdc-checkbox:hover .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, .mdc-checkbox:hover .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
  border-color: $FSX-primary !important;
  background-color: $FSX-primary !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background,
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-full.mat-pseudo-checkbox-indeterminate{
  border-color: $FSX-primary !important;
  background-color: $FSX-primary !important;
}

// Modal form
fsx-modal-with-text form, fsx-modal form {
  width: auto;
}



/** SnackBar styling */
.mat-mdc-snack-bar-container {
  background-color: white;

  .mdc-snackbar__surface {
    min-width: unset !important;
  }

  .mdc-snackbar__label {
    padding-bottom: .5rem;
    padding-right: .5rem;
    padding-top: .5rem;
  }

  mat-icon {
    font-size: 1rem;
    height: 16px;
    margin-right: .5rem;
    width: 16px;
  }
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: transparent !important;
}

.mdc-snackbar.default {

  .mdc-snackbar__label {
    color: $landing-text !important;
  }
}

.mdc-snackbar.success {
  background-color: #00a889;

  .mdc-snackbar__label {
    color: white !important;
  }

  svg {
    fill: white;
  }
}

.mdc-snackbar.warning {
  background-color:#fff1e6;

  .mdc-snackbar__label {
    color: #1d2a39 !important;
  }

  svg {
    color: #ff8427;
  }
}

.mdc-snackbar.error {
  margin-top: 32px;
  background-color: $FSX-warn;

  .mdc-snackbar__label {
    color: white !important;
  }

  svg {
    color: white;
  }
}

// Mat-Spinner
.spinner {
  width: 18px !important;
  height: 18px !important;
  margin-top: auto;
  margin-bottom: auto;

  &.case-search {
    margin-left: 10px;
    margin-top: 18px;
  }
}

.progress-bar {
  height: 2px !important;
  width: 100%;
}

//----------------- Expansion Panel Styles -----------------
app-expansion-panel {
  .panel-section {
    padding: 18px 24px;
    background: $white;
  }
  overflow-x: scroll;
}
mat-expansion-panel {
  &.filing-section {
    margin-bottom: 5px;

    .mat-expansion-panel-body {
      padding-bottom: 24px;
      overflow-x: auto;
    }
  }
  &.supporting-doc-panel {
    margin-bottom: 5px !important;

    .mat-expansion-panel-body {
      padding-bottom: 24px;
      overflow-x: auto;
    }
  }
  .mdc-icon-button {
    justify-content: center;

    .delete {
      color: $icon-tertiary;

      svg {
        height: 18px;
        width: 18px;
      }
    }

    &:hover, &:focus {
      .delete {
        color: $icon-secondary;
      }
    }

    &:focus {
      border: 2px solid #000;
    }

    .mat-mdc-button-persistent-ripple {
      display: none;
    }
  }
}
.mat-accordion>.mat-expansion-panel-spacing:last-child, .mat-accordion>*:last-child:not(.mat-expansion-panel) .mat-expansion-panel-spacing {
  margin-bottom: 5px !important;
}
fsx-button.btn {
  &.cancel {
    button {
      background: white;
      .label {
        color: $text-tertiary !important;
      }
    }
  }
}
.subsection {
  fsx-input {
    .form-error {
      margin-left: 0;
    }
  }
}

// ----------------- Ngx-mat-datepicker style fixes -----------------
.mat-calendar {
  display: block;
  font-family: var(--mat-datepicker-calendar-text-font);
  font-size: var(--mat-datepicker-calendar-text-size);
}
.mat-datepicker-content {
  background: white;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
  0px 1px 10px 0px rgba(0, 0, 0, 0.12); // angular's box shadow for datepicker

  .mat-calendar-table-header th {
    text-align: center;
    padding: 0 0 8px 0;
    color: var(--mat-datepicker-calendar-header-text-color);
    font-size: var(--mat-datepicker-calendar-header-text-size);
    font-weight: var(--mat-datepicker-calendar-header-text-weight);
  }

  // Removes the circles around the dates
  .mat-calendar-body-cell-content {
    border: none;
    font-weight: 400;

    // When date is hovered
    &:hover {
      background: #4767f917;
    }

    // When date is selected
    &.mat-calendar-body-selected {
      background: $FSX-primary;
      color: #fff;
    }
  }

  .ngx-mat-timepicker {
    padding-bottom: 10px;
    form {
      width: 200px;
    }
    tr,
    td {
      background-color: #ffffff01 !important;
      &:hover {
        background-color: #ffffff01 !important;
      }
    }
    tr {
      padding-top: 5px;
    }
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
    button {
      margin-top: 3px;
    }
    .mat-mdc-icon-button {
      border: none;

      &:hover {
        background-color: #4767f917;
      }
    }

    .mat-mdc-button {
      &:hover {
        background-color: #4767f917;
      }
    }
  }
}

/** Custom Styles for Mat Select **/
.mat-mdc-select {
  border: 1px solid $button-secondary-disabled;
  display: block !important;
  width: 300px !important;

  .mat-mdc-select-arrow svg {
    left: 0;
  }

  .mat-mdc-select-value {
    padding-left: 6px;
  }
}

.disabled {
  pointer-events: none;
  cursor: not-allowed !important;
  opacity: 0.5;
  color: $text-tertiary;
}

.modal-container {
  footer {
    border: none;
  }
}

.print-button {
  mat-icon {
    width: 14px !important;
    height: 14px !important;
    color: $FSX-secondary !important;
  }
}

.case-date-time {
  mat-datepicker-toggle {
    right: 20px !important;
  }
}

.consolidation-radio-group {
  mat-radio-button {
    margin-right: 20px;

    label {
      font-size: .75rem;
      line-height: 1.5;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      color: $landing-text;
    }
  }
}

.casenum {
  .input-container {
    margin-bottom: 0 !important;
    input {
      max-width: 150px;
    }
  }
}

.attorney-accordion {
  .mat-expansion-panel-header-title {
    &:hover,
    &:focus-visible       {
      background: none !important;
    }
  }
  .mat-expansion-panel-body {
    padding: 16px;
    background: $background;
  }
}

.documents-drawer {
  // view height - (fsx-menu-bar height + footer height)
  height: calc(100vh - (47px + 52px));
  .content {
    min-height: -webkit-fill-available;
  }
}

.delete-docket-dialog,
.manage-users-modal-dialog {
  footer {
    padding-bottom: 0;
  }
}

// Mat autocomplete
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after {
  color: $FSX-primary;
}

#case-name-label {
  .required-label {
    visibility: hidden;
  }
}

fsx-button {
  &.submission.back {
    &:hover {
      button {
        background: $FSX-secondary;
        span {
          color: $white !important;
        }
      }
    }
  }
}

.profile-modal-dialog {
  max-width: 500px !important;
}

.paginator {
  .mat-mdc-select {
    border: none;
    width: auto !important;
  }
}

.reports-select {
  label {
    margin: 0 !important;
  }

  .select-container {
    margin-top: 5px;

    select {
      height: 32px !important;
    }
  }
}

.reports-date-range-picker {
  mat-form-field {
    height: 32px;
    margin-top: 5px;

    .mat-mdc-form-field-infix {
      margin: auto !important;
    }
    .mat-mdc-form-field-icon-suffix {
      padding-top: 2px;
    }
  }
}

.generate-btn {
  margin-top: 18px;

  button {
    height: 32px !important;
  }
}

.reports-autocomplete {
  label {
    margin-bottom: 0 !important;
  }

  input {
    height: 32px !important;
    margin-top: 5px;
  }
}
.btn {
  &.stamp {
    mat-icon {
      svg {
        width: 20px !important;
        height: 20px !important;
      }
    }
  }
}

.mat-expansion-panel-header {
  &:focus {
    border: 2px solid #000;
  }
}

// Mobile expansion panel styles
.mobile-instructions {

  mat-expansion-panel-header {

    .mat-content {
      flex: none !important;
    }

    mat-panel-title {
      margin-right: 5px;
    }

    .mat-expansion-indicator::after {
      color: #000 !important;
      vertical-align: unset;
    }
  }

  .mat-expansion-panel-body {
    padding: 0 5px 16px;
  }
}

/*
  Angular material Overrides.
  Apply the class to any instance of ExpansionPanelComponent to remove box-shadow.
  e.g:
    <app-expansion-panel class="raw-expansion-panel">
      ...
    /<app-expansion-panel>
*/
.raw-expansion-panel {

  .mat-expansion-panel {
    box-shadow: none !important;
    margin-top: 16px !important;
  }

  .expansion-panel .mat-expansion-panel-header,
  .mat-expansion-panel-body {
    padding: 0 0 0 5px !important;
    margin: 0 !important;
  }

  .mat-expansion-panel-header,
  .mat-expansion-panel-header.mat-expanded {
    height: 30px !important;
  }

  .expansion-panel-content {
    padding: 0 !important;
    margin: 0 !important;
    background: transparent !important;
  }
}

.modal-dialog-mobile {
  //height: 100vh;
  //max-width: 100vw !important;
}

.force-gpu {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

fsx-sidenav {
  .mat-sidenav-content {
    //height: 100dvh !important;
  }
}

.break-word {
  word-wrap: break-word;
  word-break: break-word;
}
