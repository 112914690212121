$FSX-primary: #4767f9;
$FSX-secondary: #3b4cec;
$FSX-tertiary: #254BB6;
$FSX-warn: #CC0000;
$FSX-green: #006C57;

$alt-1: #50b4ff;
$alt-2: #00d3ac;

$background: #f7f7f7;
$alt-background: #d9e0ff;
$shadow: #cbcbcb;
$alt-table-row-background: #f9f9fb;

$border: #c3c4c3;
$border-secondary: #e2e2e2;
$border-tertiary: #e1e3ee;

$text-primary: #000;
$text-secondary: $FSX-primary;
$text-tertiary: #555555;
$text-alt: #2f445b;

$icon-primary: #000;
$icon-secondary: $FSX-primary;
$icon-tertiary: #6c707f;

$button-primary: $FSX-primary;
$button-secondary: $background;
$button-primary-disabled: #c4c9f9;
$button-secondary-disabled: #bcbcc5;
$button-cancel-text: #7e7e7e;

// Trial Binder Colors (Names not final)
$TB-primary: #3070c7;
$TB-tertiary: #0f2d5a;
$TB-shadow: #ededed;
$TB-alt-row: #f7f7f7;
$TB-text-alt: #2f445b;
$TB-text-alt-2: #1d2a39;
$TB-active: #eaf0f9;
$TB-hover: $TB-shadow;
$TB-tree-secondary-text: #657080;
$TB-tree-alt-text: #0072ce;
$TB-tree-hover: #e4f3fc;
$TB-footer-border: #eaeaea;
$TB-active-row: rgba(0, 114, 206, 0.1);
$TB-button-inactive: #919191;

// Scrollbar Colors
$scrollbar-thumb: $TB-primary;
$scrollbar-inactive: $TB-primary;
$scrollbar-track: $TB-tree-hover;

// Agency Custom Colors
$landing-bg: #f6f8fa;
$landing-text: #242424;
$clear-icon: #949494;
$warning-fill: #ff8427;
$info-label: #5d6a7e;
$status-new: $FSX-green;
$status-rejected: $FSX-warn;
$status-pending: $FSX-tertiary;
$status-active: $FSX-tertiary;
$status-inactive: rgba(0, 0, 0, 0.8);
$error-stripe: #ff9090;
$divider: #ededed;
$doc-upload-bg: #f2f4ff;
$doc-upload-border: #4766ff;
$header-green: #effaf8;
$public-docket-search-btn: #254BB6FF;
$public-docket-search-btn-hover: #4766FFFF;

// Basic Colors
$white: #fff;
$whitesmoke: whitesmoke;
